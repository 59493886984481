import React from 'react'
import { Link } from 'react-router-dom'
import claimsBg from '../../Assets/Images/launcher-bg-images/claims.jpeg'
import marketingBg from '../../Assets/Images/launcher-bg-images/marketing.jpeg'
import paymentsBg from '../../Assets/Images/launcher-bg-images/payments.jpeg'
import reportingBg from '../../Assets/Images/launcher-bg-images/reporting.jpeg'
import salesBg from '../../Assets/Images/launcher-bg-images/sales.jpeg'
import technicalBg from '../../Assets/Images/launcher-bg-images/technical.jpeg'
import trainingBg from '../../Assets/Images/launcher-bg-images/training.jpeg'
import userManagementBg from '../../Assets/Images/launcher-bg-images/user-management.jpeg'
import { Button } from 'react-bootstrap'

interface LauncherCardItem {
  name: string
  link: string
  bg: string
  classes: string
}

export const DashboardContent = () => {
  const LauncherCards: LauncherCardItem[] = [
    {
      name: 'Warranty Sales',
      link: 'sales',
      bg: salesBg,
      classes: 'col-4 launcher-card',
    },
    {
      name: 'Payments',
      link: 'payment',
      bg: paymentsBg,
      classes: 'col-4 launcher-card',
    },
    {
      name: 'Warranty Claims',
      link: 'claim',
      bg: claimsBg,
      classes: 'col-4 launcher-card',
    },
    {
      name: 'Marketing Support',
      link: 'marketing',
      bg: marketingBg,
      classes: 'col-6 launcher-card-wide',
    },
    {
      name: 'Training Support',
      link: 'training',
      bg: trainingBg,
      classes: 'col-6 launcher-card-wide',
    },
    {
      name: 'Reporting',
      link: 'report',
      bg: reportingBg,
      classes: 'col-4 launcher-card',
    },
    {
      name: 'User Management',
      link: 'profile',
      bg: userManagementBg,
      classes: 'col-4 launcher-card',
    },
    {
      name: 'Technical Support',
      link: 'support',
      bg: technicalBg,
      classes: 'col-4 launcher-card',
    },
  ]

  return (
    <div className='text-start my-5'>
      <h6 className='fw-normal fs-4 mb-4'>Welcome to OnPoint&apos;s Dealer Portal</h6>
      <p className='fw-light text-secondary fs-6 mb-2'>
        Here you can submit warranty sales, view plans, submit cancellations and acquire account
        reports.
      </p>
      <p className='fw-light text-secondary fs-6 mb-4'>What would you like to do today?</p>
      <div className='row m-0'>
        {LauncherCards.map((item) => (
          <div className='col-12 col-sm-6 col-md-4 px-1 mb-2' key={item.name.trim()}>
            <div
              className='w-100 h-100 d-flex align-items-end justify-content-center text-center px-2 py-3 text-white rounded launcher-card'
              style={{
                background: `linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(4, 4, 4, 0) 50%),url(${item.bg}) center center / cover no-repeat`,
                height: '100vh',
              }}
            >
              <div className='w-100'>
                <Link to={item.link} className='text-decoration-none'>
                  <Button
                    variant='default'
                    className='text-black bg-white opacity-50'
                    style={{ opacity: 50, zIndex: 1000 }}
                  >
                    {item.name}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DashboardContent
