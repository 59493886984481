import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DashboardContent from './Components/DashboardContent'
import DashboardScreensLayout from './Components/DashboardScreensLayout'
import { Layout } from './Components/Layout'
import { NavigationContextProvider } from './contexts/NavigationContextProvider'
import NotFound from './pages/404/NotFound'
import Login from './pages/Auth/login'
import ClaimListTable from './pages/claim/ClaimListTable'
import ClaimScreenForDashboard from './pages/claim/ClaimScreenForDashboard'
import ClaimDetail from './pages/claim/details'
import SalesDetail from './pages/Sales/SalesScreenForDashboard'
import Upload from './pages/Sales/SalesUpload'

function App() {
  return (
    <div className='App' style={{ height: '100vh', overflow: 'hidden' }}>
      <NavigationContextProvider>
        <Layout>
          <Routes>
            <Route path='/'>
              <Route path='' element={<DashboardContent />} />
              <Route element={<DashboardScreensLayout />}>
                <Route path='claim'>
                  <Route path='' element={<ClaimScreenForDashboard />} />
                  <Route path='list' element={<ClaimListTable />} />
                  <Route path=':id' element={<ClaimDetail />} />
                </Route>
                <Route path='sales'>
                  <Route path='' element={<SalesDetail />} />
                  <Route path='upload' element={<Upload />} />
                </Route>
              </Route>
              <Route path='*' element={<NotFound />} />
            </Route>
            <Route path='/login' element={<Login />} />
          </Routes>
        </Layout>
      </NavigationContextProvider>
    </div>
  )
}

export default App
