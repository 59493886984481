import React, { useContext, useEffect, useState } from 'react'
import { Row, Spinner } from 'react-bootstrap'
import { getFlightTrackerStatusList } from '../../services/flightTracker.api'
import { IFlightStatus } from '../../types/flightStatus'
import AuthContext from '../../contexts/AuthContext'

interface FlightStatusBarProps {
  currentStatus: string
}

export const FlightStatusBar = (props: FlightStatusBarProps) => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState<IFlightStatus[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const list = await getFlightTrackerStatusList(authContext?.data?.token)
        setData(list?.data?.request)
        setLoading(false)
      } catch (err) {
        setData([])
        setError('Something went wrong!')
        setLoading(false)
      }
    }
    fetchData()
  }, [authContext])

  useEffect(() => {
    error.length && console.error(error)
  }, [error])

  return (
    <Row>
      <div className='claim-status__wrapper mt-3'>
        {loading ? (
          <div className='d-flex justify-content-center align-items-center mt-5 mb-3'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          data?.map((item) => {
            if (item.flightTrackerStatusDealer?.toLowerCase() !== 'Cancelled'.toLowerCase()) {
              const classList =
                props.currentStatus != undefined &&
                item.flightTrackerStatusDealer?.toLowerCase() === props.currentStatus?.toLowerCase()
                  ? 'claim-status active-status'
                  : 'claim-status'
              return (
                <div className={classList} key={item.sortOrder}>
                  {item.flightTrackerStatusDealer}
                </div>
              )
            }
          })
        )}
      </div>
    </Row>
  )
}
