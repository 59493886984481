import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
interface Breadcrumb {
  name: string
  path: string
}
//Dynamic breadcrumb route should be added here to show breadcrumb
const breadcrumbMap: { [key: string]: Breadcrumb } = {
  '/claim': { name: 'Warranty Claims', path: '/claim' },
  '/sales': { name: 'Warranty Sales Processing', path: '/sales' },
  '/sales/upload': { name: 'Warranty File Upload', path: '/sales/upload' },
}

const BreadcrumbsCustom = () => {
  const location = useLocation()
  const { pathname } = location
  //Breadcrumb Logic for dynamic breadcrumb
  const getBreadcrumbs = (pathname: string): { name: string; path: string }[] => {
    const pathnames = pathname.split('/').filter((x) => x)
    const breadcrumbPaths: { name: string; path: string }[] = []
    let cumulativePath = ''

    pathnames.forEach((segment) => {
      cumulativePath += `/${segment}`
      const breadcrumb = breadcrumbMap[cumulativePath]

      if (breadcrumb) {
        breadcrumbPaths.push({ name: breadcrumb.name, path: breadcrumb.path })
      } else {
        // Pushes a placeholder breadcrumb if no exact match found
        breadcrumbPaths.push({
          name: segment.charAt(0).toUpperCase() + segment.slice(1),
          path: cumulativePath,
        })
      }
    })

    return breadcrumbPaths
  }

  const breadcrumbs = getBreadcrumbs(pathname)

  return (
    <Breadcrumb className='mt-3'>
      <Breadcrumb.Item href='/'>Dashboard</Breadcrumb.Item>
      {breadcrumbs.map((crumb, index) => (
        <Breadcrumb.Item active key={index} href={crumb.path}>
          {crumb.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default BreadcrumbsCustom
