import React from 'react'
import cardBg from '../../../Assets/Images/claims-card-bg.jpeg'
import { Link } from 'react-router-dom'

const ClaimScreenForDashboard = () => {
  return (
    <div className='text-start my-5'>
      <h6 className='fw-normal fs-4 mb-4'>Welcome to OnPoint&apos;s Dealer Portal</h6>
      <p className='fw-light text-secondary fs-6 mb-2'>
        Here you can submit warranty sales, view plans, submit cancellations and acquire account
        reports.
      </p>
      <p className='fw-light text-secondary fs-6 mb-4'>What would you like to do today?</p>
      <div className='row m-0'>
        <div className={`px-1 mb-2 launcher-card col-4`}>
          <Link
            className='w-100 h-100 d-flex align-items-end justify-content-center text-capitalize py-3 text-white text-decoration-none rounded launcher-card-hover'
            to={'list'}
            style={{
              background: `linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(4, 4, 4, 0) 50%),url(${cardBg}) center center / cover no-repeat`,
            }}
          >
            <span style={{ position: 'relative', zIndex: '1000' }}>View Claims information</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ClaimScreenForDashboard
