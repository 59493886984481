// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
  .pagination-container { 
    position: fixed;
    flex-direction: column;
    bottom: 0;
    background: #eff4f5;
    width: 100vw;
    left: 0;
    margin: 0 !important;
  }
  .pagination-container .per-page {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .table-container {
    padding-bottom: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/claim/ClaimListTable/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;IACf,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,YAAY;IACZ,OAAO;IACP,oBAAoB;EACtB;EACA;IACE,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,oBAAoB;EACtB;AACF","sourcesContent":["@media (max-width: 767px) {\n  .pagination-container { \n    position: fixed;\n    flex-direction: column;\n    bottom: 0;\n    background: #eff4f5;\n    width: 100vw;\n    left: 0;\n    margin: 0 !important;\n  }\n  .pagination-container .per-page {\n    margin-bottom: 5px;\n    margin-top: 5px;\n  }\n  .table-container {\n    padding-bottom: 80px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
