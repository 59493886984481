import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import uploadBg from '../../../Assets/Images/upload-sales-bg.jpeg'
import entersaleBg from '../../../Assets/Images/enterSale.jpeg'
import viewsaleBg from '../../../Assets/Images/viewSales.jpeg'
import { Container, Row, Col } from 'react-bootstrap'

interface LauncherCardItem {
  name: string
  link: string
  bg: string
  classes: string
}

const LauncherCards: LauncherCardItem[] = [
  {
    name: 'Upload a Warranty Sales File',
    link: 'upload',
    bg: uploadBg,
    classes: 'col-12 col-sm-6 col-md-4 px-1 mb-2 launcher-card',
  },
  {
    name: 'Enter a Warranty Sale',
    link: '',
    bg: entersaleBg,
    classes: 'col-12 col-sm-6 col-md-4 px-1 mb-2 launcher-card',
  },
  {
    name: 'View Warranty Sales',
    link: 'claim',
    bg: viewsaleBg,
    classes: 'col-12 col-sm-6 col-md-4 px-1 mb-2 launcher-card',
  },
]

function index() {
  return (
    <Fragment>
      <Container>
        <Row className='mt-5 mb-3'>
          <Col className='text-start'>
            <h6 className='fw-normal fs-4'>Warranty Sales Processing</h6>
          </Col>
        </Row>
        <Row className='row m-0'>
          {LauncherCards.map((item) => (
            <Col className={item.classes.trim()} key={item.name.trim()}>
              <Link
                className='w-100 h-100 d-flex align-items-end justify-content-center text-capitalize py-3 text-white text-decoration-none rounded launcher-card-hover'
                to={item.link.trim()}
                style={{
                  background: `linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(4, 4, 4, 0) 50%),url(${item.bg.trim()}) center center / cover no-repeat`,
                }}
              >
                <span style={{ position: 'relative', zIndex: '1000' }}>{item.name.trim()}</span>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  )
}

export default index
