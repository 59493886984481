import React from 'react'
import { Pagination } from 'react-bootstrap'

interface CustomPaginationProps {
  totalPages: number
  currentPage: number
  handleChangePage: (page: number) => void
}

const CustomPagination = ({ totalPages, currentPage, handleChangePage }: CustomPaginationProps) => {
  const renderPaginationItems = () => {
    const items = []
    const visiblePages = 3 // Number of pagination items to show
    const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2))
    const endPage = Math.min(totalPages, startPage + visiblePages - 1)

    // Add previous page arrow
    if (currentPage > 1) {
      items.push(<Pagination.Prev key='prev' onClick={() => handleChangePage(currentPage - 1)} />)
    }

    // Add first page if not in the range
    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handleChangePage(1)}>
          {1}
        </Pagination.Item>,
      )
      // Add ellipsis if necessary
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key='ellipsis1' />)
      }
    }

    // Add pagination items within the visible range
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handleChangePage(i)}>
          {i}
        </Pagination.Item>,
      )
    }

    // Add last page if not in the range
    if (endPage < totalPages) {
      // Add ellipsis if necessary
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key='ellipsis2' />)
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handleChangePage(totalPages)}>
          {totalPages}
        </Pagination.Item>,
      )
    }

    // Add next page arrow
    if (currentPage < totalPages) {
      items.push(<Pagination.Next key='next' onClick={() => handleChangePage(currentPage + 1)} />)
    }

    return items
  }

  return <Pagination className='m-0'>{renderPaginationItems()}</Pagination>
}

export default CustomPagination
