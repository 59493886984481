import axios from 'axios'

export const getClaimDetails = async (
  serviceOrderNumber: string | undefined,
  _token: string | null = null,
) => {
  if (!serviceOrderNumber) return null
  let token = _token
  if (!token) {
    token = localStorage.getItem('token')
    token = localStorage.getItem('token')
  }
  if (token) {
    const url =
      process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT +
      '/claim?serviceOrderNumber=' +
      serviceOrderNumber

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    const detailsRequest = await axios.get(url, { headers: headers })
    if (detailsRequest?.data) return detailsRequest?.data
    else return null
  } else {
    return null
  }
}

export const getClaimListByDealerNumber = async (
  dealerNumber: string,
  _token: string | null = null,
) => {
  if (!dealerNumber) return null
  let token = _token
  if (!token) {
    token = localStorage.getItem('token')
    token = localStorage.getItem('token')
  }
  if (token) {
    const url = `${process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT}/claim/list?dealerNumber=${dealerNumber}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    const detailsRequest = await axios.get(url, { headers: headers })
    if (detailsRequest?.data) return detailsRequest?.data
    else return null
  }
}
