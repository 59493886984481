import React, { useState } from 'react'
import { Navbar, Offcanvas } from 'react-bootstrap'
// import glassIcon from '../../Assets/Icons/magnifying-lens.png'
import MainLogo from '../../Assets/Images/ServiceAvengerLogo.png'
import LoginSideBar from './LoginSideBar'
import UserAvatar from './UserAvatar'

export default function LoginHeader() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <header
      className='px-2 px-sm-3 px-lg-5 py-2 bg-white'
      style={{ height: '53px', borderBottom: '1px solid #EEEEEE' }}
    >
      <div className='d-flex justify-content-between h-100'>
        <div className='d-flex'>
          <Navbar collapseOnSelect expand='lg' className='p-0' bg='white' data-bs-theme='white'>
            <Navbar.Toggle onClick={handleShow} />
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <img src={MainLogo} alt='Logo' height={37} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='overflow-scroll'>
                <LoginSideBar />
              </Offcanvas.Body>
            </Offcanvas>
          </Navbar>
          <img src={MainLogo} alt='Logo' className='h-100' />
        </div>
        <div className='d-flex align-items-center flex-shrink-1'>
          <UserAvatar />
        </div>
      </div>
    </header>
  )
}
