import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'

function index() {
  const handleDownload = (): void => {
    const url: string | undefined = process.env.REACT_APP_FILE_SERVICE
    if (!url) {
      toast.error('The URL for the CSV file is not defined. Please contact support.')
      return
    }
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url + '/sales/SalesBulkTemplate.csv'
    link.setAttribute('download', 'Sales.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <Container className='text-start my-5'>
      <Row>
        <Col>
          <h6 className='fw-normal fs-4 mb-4'>Warranty File Upload</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <Form.Group controlId='fileManagerInput' className='mb-3'>
            <Form.Label>Name:</Form.Label>
            <Form.Control type='file' id='fileManagerInput' placeholder='Explore file manager' />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Button
            className='mb-4 border-0'
            onClick={() => {
              handleDownload()
            }}
            variant='outline-dark'
          >
            Download Template
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <Button variant='primary' className='me-1'>
            Proceed to payment
          </Button> */}
          <Button variant='secondary' className='ms-1'>
            Reset
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default index
