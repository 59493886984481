import React from 'react'
import BreadcrumbsCustom from './BreadcrumbsCustom'
import { Outlet } from 'react-router-dom'

const DashboardScreensLayout = () => {
  return (
    <>
      <BreadcrumbsCustom />
      <Outlet />
    </>
  )
}

export default DashboardScreensLayout
