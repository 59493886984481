// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-status__wrapper {
  display: flex;
  /* flex-wrap:wrap; */
  overflow-x: scroll;
  /* justify-content: center; */
  margin: 20px 0px;
}

/* .claim-status__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(102px, 1fr));
  grid-gap: 5px;
  justify-content: center;
  padding: 20px 0;
} */

.claim-status {
  width: 20px;
  /* width: 100%; */
  height: 52px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(180deg, #4fd3f2 0%, #009abe 98.96%);
  border-right: 1px solid #ffffff4d;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 102px;
}

.claim-status.active-status {
  width: 20px;
  height: 52px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(180deg, #eb8f7f 0%, #e56a54 98.96%);
  border-right: 1px solid #ffffff4d;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 102px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/claim/details/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,kBAAkB;EAClB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;;;;;;GAMG;;AAEH;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,+DAA+D;EAC/D,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,+DAA+D;EAC/D,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".claim-status__wrapper {\n  display: flex;\n  /* flex-wrap:wrap; */\n  overflow-x: scroll;\n  /* justify-content: center; */\n  margin: 20px 0px;\n}\n\n/* .claim-status__wrapper {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(102px, 1fr));\n  grid-gap: 5px;\n  justify-content: center;\n  padding: 20px 0;\n} */\n\n.claim-status {\n  width: 20px;\n  /* width: 100%; */\n  height: 52px;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  text-align: center;\n  background: linear-gradient(180deg, #4fd3f2 0%, #009abe 98.96%);\n  border-right: 1px solid #ffffff4d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 102px;\n}\n\n.claim-status.active-status {\n  width: 20px;\n  height: 52px;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  text-align: center;\n  background: linear-gradient(180deg, #eb8f7f 0%, #e56a54 98.96%);\n  border-right: 1px solid #ffffff4d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 102px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
